import { Layout, Spin } from "antd"
import dynamic from "next/dynamic"
import Router from "next/router"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import { AuthContext } from "./utils/AuthContext"

const SideNav = dynamic(
  () =>
    import("./components/organisms/SideNav/index").then((mod) => mod.SideNav),
  { ssr: false }
)

const { Sider, Content } = Layout

const Aside = styled(Sider)`
  flex: 0 0 255px !important;
  max-width: 255px !important;
`

const LayoutWrapper = styled.div`
  max-width: calc(100% - 25px);
  min-width: calc(100% - 255px);
  & .ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout-content {
    min-height: 92vh;
    padding-bottom: 0;
    /* background-color: #f3f4f6 !important; */
  }
  & .ant-layout-footer {
    padding-top: 0;
  }
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`
const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const { authenticated, loading, user, companyInfo } =
      useContext(AuthContext)
    const restrictedRoutes = companyInfo?.restrictions || []
    const companyRoutes = [
      "/dashboard",
      "/jobs",
      "/agent-management",
      "/selection-management",
      "/settings",
    ]

    useEffect(() => {
      const { pathname } = Router
      if (!loading) {
        if (!authenticated) {
          Router.push("/login")
          return
        }
        if (restrictedRoutes.includes(pathname)) {
          const remainingRoutes = companyRoutes.filter(
            (route) => !restrictedRoutes.includes(route)
          )
          if (remainingRoutes.length > 0) {
            Router.push(remainingRoutes[0])
          }
          return
        }

        if (authenticated && pathname === "/") {
          window.location.href = "/dashboard"
        }
      }
    }, [authenticated, loading, user, companyInfo?.restrictions])

    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size="large" className="loader" />
        </LoaderWrapper>
      )
    }
    return <>{authenticated && <> {children} </>} </>
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <Layout>
            <Aside trigger={null} collapsed={false}>
              <SideNav />
            </Aside>
            <LayoutWrapper>
              <Content>
                <AuthComponent {...this.props} />
              </Content>
            </LayoutWrapper>
          </Layout>
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
